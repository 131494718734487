<template>
  <div>
    <!-- Table Container Card -->
    <SentInvitations
      :refreshed="refreshed"
      :ison="page"
      :selectedUser="{}"
      :selectedAccount="selectedAccount"
      :roles="roles"
    />
    <UpdateUserRole
      :ison="page"
      :refreshed="refreshed"
      :selectedUser="selectedUser"
      :selectedAccount="selectedAccount"
      :roles="roles"
      v-if="isSentInvite"
    />
    <b-card no-body class="mb-0">
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="1"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        class="position-relative no-headers userlist-table"
        responsive
        show-empty
        v-if="!show"
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->
        <template #cell(user)="row">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                :src="row.item.profile_pic_url"
                :text="row.item.avtar_txt"
                :variant="row.item.variant"
              />
            </template>
            <h6 class="mb-0">
              {{ row.item.first_name }} {{ row.item.last_name
              }}<span class="text-primary">
                ({{ row.item.primary_role }})
              </span>
            </h6>
            <small class="text-muted">{{ row.item.email }}</small>
          </b-media>
        </template>

        <template #cell(action)="row">
          <div class="text-right list-icon">
            <span :id="row.item.up_tool">
              <feather-icon
                v-if="
                  selectedAccount && selectedAccount.id
                    ? selectedAccount.type === 'DEALER'
                      ? checkAbility({
                          action: constants.PERMISSIONS_ACTION.UPDATE,
                          subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT
                        })
                      : selectedAccount.type === 'CONSUMER'
                      ? checkAbility({
                          action: constants.PERMISSIONS_ACTION.UPDATE,
                          subject:
                            constants.PERMISSIONS_MODEL.USER_ACCOUNT_CONSUMER
                        })
                      : checkAbility({
                          action: constants.PERMISSIONS_ACTION.UPDATE,
                          subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT
                        })
                    : checkAbility({
                        action: constants.PERMISSIONS_ACTION.UPDATE,
                        subject: constants.PERMISSIONS_MODEL.USERS
                      }) && row.item.isEditable
                "
                icon="EditIcon"
                style="position: relative; margin-right: 10px; cursor: pointer"
                class="text-primary action-icon"
                size="18"
                v-b-tooltip.hover.top="$t('tooTip.update')"
                @click="updateUserRoll(row.item)"
              />
            </span>
            <span :id="row.item.del_tool">
              <feather-icon
                v-if="
                  selectedAccount && selectedAccount.id
                    ? selectedAccount.type === 'DEALER'
                      ? checkAbility({
                          action: constants.PERMISSIONS_ACTION.DELETE,
                          subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT
                        })
                      : selectedAccount.type === 'CONSUMER'
                      ? checkAbility({
                          action: constants.PERMISSIONS_ACTION.DELETE,
                          subject:
                            constants.PERMISSIONS_MODEL.USER_ACCOUNT_CONSUMER
                        })
                      : checkAbility({
                          action: constants.PERMISSIONS_ACTION.DELETE,
                          subject: constants.PERMISSIONS_MODEL.USER_ACCOUNT
                        })
                    : checkAbility({
                        action: constants.PERMISSIONS_ACTION.DELETE,
                        subject: constants.PERMISSIONS_MODEL.USERS
                      }) && row.item.isDeletable
                "
                icon="Trash2Icon"
                class="text-danger action-icon"
                size="18"
                style="position: relative; cursor: pointer"
                v-b-modal.modal-sm-remove
                v-b-tooltip.hover.top="$t('tooTip.delete')"
                @click="close(row.item)"
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-if="totalUsers > 0"
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              :no-paging-nav="false"
              :active-class="activeClass"
              @input="handlePageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AreYouSureModal
      ref="areyousure"
      :data="user"
      :onClose="onClose"
      :removedUser="removedUser"
    />
    <!-- <Loader :show="show" /> -->
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip,
  BTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import AccountService from "@/libs/api/account-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import UpdateUserRole from "@/layouts/components/UserOnboarding/updateUserRole.vue";
import SentInvitations from "@/layouts/components/UserOnboarding/SentInvitations.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    UpdateUserRole,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AreYouSureModal,
    BTooltip,
    VBTooltip,
    BSkeletonTable,
    SentInvitations
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      tableColumns: [
        {
          key: "user",
          tdClass: "user-group-width-1",
          thClass: "user-group-width-1",
          label: this.$t("historyTableColumns.user")
        },
        {
          key: "labels",
          tdClass: "user-group-width",
          thClass: "user-group-width",
          label: this.$t("historyTableColumns.labels")
        },
        {
          key: "action",
          tdClass: "user-action-width",
          thClass: "user-action-width",
          label: this.$t("historyTableColumns.action")
        }
      ],

      totalUsers: 0,
      page: "user",
      user: {},

      roles: [],
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.currentPage
        ? this.$route.query.currentPage
        : 1,
      items: [],
      loggedUser: {},
      show: false,
      isSentInvite: false,
      selectedUser: {},
      isUpdateRole: false,
      activeClass: "active",
      timeoutId: null
    };
  },
  props: ["accountId", "selectedAccount"],
  mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;

      if (!page) {
        this.updateUrl({ key: "page", value: 1 });
      } else {
        this.currentPage = parseInt(page);
      }

      if (filter) {
        this.filter = filter;
      }

      if (perPage) {
        this.perPage = parseInt(perPage);
      }
    });

    this.debounceGetAllUser();
    this.getRoles();
    if (
      this.$route.name === "dealer" ||
      this.$route.name === "sub_dealer" ||
      this.$route.name === "consumer"
    ) {
      this.$route.meta.breadcrumb.push({
        text: "Breadcrumb_Users",
        index: "sub_acc_user",
        active: true
      });
      this.$router.replace({ query: { tab: "users" } }).catch((e) => {});
    }
  },
  watch: {
    perPage(val) {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetAllUser();
    },
    currentPage(newPage) {
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.debounceGetAllUser();
    },
    $route(to, from) {
      if (
        this.$route.name === "dealer" ||
        this.$route.name === "sub_dealer" ||
        this.$route.name === "sub_consumer" ||
        this.$route.name === "consumer"
      ) {
        if (
          this.$route.query &&
          (this.$route.query.tab === "users" ||
            this.$route.query.tab === "invitations")
        ) {
          return;
        } else {
          this.$router.go();
        }
      }
    }
  },
  methods: {
    debounceGetAllUser() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        this.getAllUser();
      }, 300); // Adjust the delay as necessary
    },
    async getAllUser() {
      try {
        this.show = true;

        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().getUserDealerSubAccounts({
                  page: this.currentPage,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null,
                  page_size: parseInt(this.perPage)
                })
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().getUserConsumerSubAccounts({
                  page: this.currentPage,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null,
                  page_size: parseInt(this.perPage)
                })
              : await new AccountService().getUserSubAccounts({
                  page: this.currentPage,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null,
                  page_size: parseInt(this.perPage)
                })
            : await new AccountService().getAccountUsers({
                page: this.currentPage,
                account_id:
                  (this.selectedAccount && this.selectedAccount.id) || null,
                page_size: parseInt(this.perPage)
              });

        let loggedUser = localStorage.getItem("USER_PROFILE_DETAILS")
          ? JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS")) || []
          : [];

        if (response && response.data) {
          this.show = false;
          this.items = response.data.users || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.del_tool = `del${u.id}`;
            u.up_tool = `up${u.id}`;
            u.variant = this.getRandomBgColor();
            u.avtar_txt =
              u.first_name &&
              u.first_name.substring(0, 1) +
                (u.last_name && u.last_name ? u.last_name.substring(0, 1) : "");

            if (loggedUser && loggedUser.id === u.id) {
              u.isDeletable = false;
              u.isEditable = false;
            } else {
              u.isEditable = true;
              u.isDeletable = true;
            }

            if (u.isEditable) {
              u.isEditable = true;
            } else {
              u.isEditable = false;
            }
            if (u.isDeletable) {
              u.isDeletable = true;
            } else {
              u.isDeletable = false;
            }
            if (u.is_owner) {
              if (u.isDeletable && u.is_restrict_remove_default_user) {
                u.isDeletable = false;
              }
              if (u.isEditable && u.is_restrict_remove_default_user) {
                u.isEditable = false;
              }
            }
            if (
              (!this.selectedAccount || !this.selectedAccount.id) &&
              !u.is_child
            ) {
              u.isDeletable = false;
              u.isEditable = false;
            }

            return u;
          });
          this.totalUsers = response.data.count || 0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    close(userData) {
      this.user = userData;
      this.$bvModal.show("modal-sm-remove");
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter && this.filter.value) {
        query.filter = this.filter.value;
      }
      this.$router.replace({ query }).catch(() => {});
      this.debounceGetAllUser();
    },
    async updateUserRoll(user) {
      if (user && user.email) {
        await this.getRoles();
        this.selectedUser = user;
        this.isUpdateRole = true;
        this.$bvModal.hide("modal-sm-invitations-user");
        this.isSentInvite = true;
        setTimeout(() => {
          this.$bvModal.show("modal-sm-invitations-user");
        }, 500);
      }
    },
    async getRoles() {
      try {
        let obj = {};
        if (this.selectedAccount && this.selectedAccount.id) {
          obj = {
            account_id: this.selectedAccount.id,
            type: this.selectedAccount.type
          };
        }
        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().getSubDealerAccountUserRoles(obj)
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().getSubConsumerAccountUserRoles(obj)
              : await new AccountService().getSubAccountUserRoles(obj)
            : await new AccountService().getUserRolesRead(obj);

        if (response && response.data) {
          this.roles = response.data.list || [];
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    async refreshed() {
      this.$bvModal.hide("modal-sm-invitations-user");
      this.debounceGetAllUser();
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
    },
    async removedUser(params) {
      try {
        this.show = true;
        if (!params || !params.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("user.UserRemovedTitle"),
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
          return false;
        }
        const user =
          localStorage.getItem("USER_PROFILE_DETAILS") &&
          JSON.parse(localStorage.getItem("USER_PROFILE_DETAILS"));
        if (user && user.id === params.id) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("user.UserRemovedTitle"),
              text: this.$t("user.UserSelfRemoveMsg"),
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
        this.$refs.areyousure.disabled = true;
        let response =
          this.selectedAccount && this.selectedAccount.id
            ? this.selectedAccount.type === "DEALER"
              ? await new AccountService().userSubDealerAccountUserRemoved({
                  user_id: params.id,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null
                })
              : this.selectedAccount.type === "CONSUMER"
              ? await new AccountService().userSubConsumerAccountUserRemoved({
                  user_id: params.id,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null
                })
              : await new AccountService().userSubAccountUserRemoved({
                  user_id: params.id,
                  account_id:
                    (this.selectedAccount && this.selectedAccount.id) || null
                })
            : await new AccountService().userRemoved({
                user_id: params.id,
                account_id:
                  (this.selectedAccount && this.selectedAccount.id) || null
              });
        if (response && response.data) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("user.UserRemovedTitle"),
              text: this.$t("user.UserRemovedMsg"),
              icon: "EditIcon",
              variant: "success"
            }
          });

          this.onClose();
          this.debounceGetAllUser();
           this.$refs.areyousure.disabled = false;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.show = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.userlist-table {
  overflow-y: auto;
  height: calc(100vh - 300px);
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
.table-responsive {
  height: calc(100vh - 315px);
}
</style>
<style lang="scss">
.userlist-table {
  overflow-y: auto;
  height: calc(100vh - 300px);
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.user-action-width {
  max-width: 120px;
  width: 120px;
  min-width: 120px;
}

.user-group-width {
  max-width: 180px;
  width: 180px;
  min-width: 180px;
  text-align: left;
  .event-Tag .media-body {
    justify-content: flex-start;
  }
}
.user-name-width {
  max-width: 220px;
  width: 220px;
  min-width: 220px;
  text-align: left;
}
</style>
