var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mt-36 added-data"},[_c('b-row',{staticClass:"mb-1"},[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile-50",attrs:{"cols":"12","md":"6"}},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.$t("role.Roleslist")))])]),(!_vm.isGroupEntity)?_c('b-col',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0 mobile-50 add-AddUnit",attrs:{"cols":"12","md":"6"}},[(
            _vm.checkAbility({
              action: _vm.constants.PERMISSIONS_ACTION.ADD,
              subject: _vm.constants.PERMISSIONS_MODEL.ROLE
            })
          )?_c('b-button',{attrs:{"size":"sm","variant":"primary","to":{ name: 'create-role' }}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"12"}}),_vm._v(" "+_vm._s(_vm.$t("roles_management.Add"))+" ")],1):_vm._e()],1):_vm._e()],1),(_vm.show)?_c('div',{staticClass:"table-responsive"},[(_vm.show)?_c('b-skeleton-table',{attrs:{"rows":10,"columns":7,"table-props":{ bordered: true, striped: true }}}):_vm._e()],1):_vm._e(),(!_vm.show)?_c('b-table',{staticClass:"position-relative no-headers user-roles-list",attrs:{"responsive":"","show-empty":"","align-v":"end","items":_vm.roles,"fields":_vm.tableColumns,"empty-text":_vm.$t('NoMatchingRecordsFound')},scopedSlots:_vm._u([{key:"cell(role_name)",fn:function(data){return [_c('b-media',{staticClass:"align-item-center",scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50","text":data.value && data.value.substring(0, 1),"variant":"light-primary"}})]},proxy:true}],null,true)},[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(data.value))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(data.item.user_count)+" "+_vm._s(_vm.$t("user.User")))]),(data.item.parent_role_id)?_c('small',{staticClass:"text-muted"},[_vm._v(" | "+_vm._s(_vm.$t("roles_management.ReportsTo"))+" "+_vm._s(data.item.parent_role_name)+" ")]):_vm._e()])]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"text-right role-create"},[_c('span',{attrs:{"id":data.item.clone_tool}},[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.ADD,
                  subject: _vm.constants.PERMISSIONS_MODEL.ROLE
                })
              )?_c('feather-icon',{staticClass:"text-primary action-icon icon-position-top",attrs:{"icon":"CopyIcon","size":"18"},on:{"click":function($event){return _vm.redirectAddscreen(data.item.id)}}}):_vm._e()],1),_c('span',{attrs:{"id":data.item.up_tool}},[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.UPDATE,
                  subject: _vm.constants.PERMISSIONS_MODEL.ROLE
                }) && !_vm.isReadOnly(data.item)
              )?_c('feather-icon',{staticClass:"text-primary action-icon icon-position-top",attrs:{"icon":"EditIcon","size":"18"},on:{"click":function($event){return _vm.goToEditRole(data)}}}):_vm._e()],1),_c('span',{attrs:{"id":data.item.view_tool}},[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.READ,
                  subject: _vm.constants.PERMISSIONS_MODEL.ROLE
                }) || _vm.isReadOnly(data.item)
              )?_c('feather-icon',{staticClass:"text-primary action-icon icon-position-top",attrs:{"icon":"EyeIcon","size":"18"},on:{"click":function($event){return _vm.goToViewRole(data)}}}):_vm._e()],1),_c('span',[(
                _vm.checkAbility({
                  action: _vm.constants.PERMISSIONS_ACTION.DELETE,
                  subject: _vm.constants.PERMISSIONS_MODEL.ROLE
                }) && !_vm.isReadOnly(data.item)
              )?_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}},{name:"b-modal",rawName:"v-b-modal.modal-sm-remove",modifiers:{"modal-sm-remove":true}}],staticClass:"text-danger action-icon icon-position",attrs:{"title":_vm.$t('tooTip.delete'),"icon":"Trash2Icon","size":"18"},on:{"click":function($event){return _vm.remove(data.item)}}}):_vm._e()],1)]),_c('b-tooltip',{attrs:{"delay":"100","variant":"primary","target":data.item.clone_tool}},[_vm._v(_vm._s(_vm.$t("tooTip.clone")))]),_c('b-tooltip',{attrs:{"delay":"100","variant":"primary","target":data.item.up_tool}},[_vm._v(_vm._s(_vm.$t("tooTip.update")))]),_c('b-tooltip',{attrs:{"delay":"100","variant":"primary","target":data.item.view_tool}},[_vm._v(_vm._s(_vm.$t("tooTip.view")))])]}}],null,false,1920532108)}):_vm._e(),_c('div',{staticClass:"mx-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}}),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalRoles,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"input":_vm.handlePageChange},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1),_c('AreYouSureModal',{ref:"areyousure",attrs:{"data":_vm.role,"onClose":_vm.onClose,"removedUser":_vm.removeRole,"customData":_vm.customData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }