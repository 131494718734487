<template>
  <div>
    <b-card class="mt-36 added-data">
      <b-row class="mb-1">
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0 mobile-50"
        >
          <h4 class="card-title">{{ $t("role.Roleslist") }}</h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0 mobile-50 add-AddUnit"
          v-if="!isGroupEntity"
          ><b-button
            size="sm"
            variant="primary"
            :to="{ name: 'create-role' }"
            v-if="
              checkAbility({
                action: constants.PERMISSIONS_ACTION.ADD,
                subject: constants.PERMISSIONS_MODEL.ROLE
              })
            "
          >
            <feather-icon icon="PlusIcon" size="12" />
            {{ $t("roles_management.Add") }}
          </b-button>
        </b-col>
      </b-row>
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="7"
          :table-props="{ bordered: true, striped: true }"
          class=""
        />
      </div>
      <b-table
        class="position-relative no-headers user-roles-list"
        responsive
        v-if="!show"
        show-empty
        align-v="end"
        :items="roles"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <!-- Column: User -->
        <template #cell(role_name)="data">
          <b-media class="align-item-center">
            <template #aside>
              <b-avatar
                size="50"
                :text="data.value && data.value.substring(0, 1)"
                variant="light-primary"
              />
            </template>
            <h6 class="mb-0">{{ data.value }}</h6>
            <small class="text-muted"
              >{{ data.item.user_count }} {{ $t("user.User") }}</small
            >
            <small v-if="data.item.parent_role_id" class="text-muted">
              | {{ $t("roles_management.ReportsTo") }}
              {{ data.item.parent_role_name }}
            </small>
          </b-media>
        </template>

        <template #cell(action)="data">
          <div class="text-right role-create">
            <span :id="data.item.clone_tool">
              <feather-icon
                icon="CopyIcon"
                class="text-primary action-icon icon-position-top"
                size="18"
                @click="redirectAddscreen(data.item.id)"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.ADD,
                    subject: constants.PERMISSIONS_MODEL.ROLE
                  })
                "
              />
            </span>
            <span :id="data.item.up_tool">
              <feather-icon
                icon="EditIcon"
                class="text-primary action-icon icon-position-top"
                size="18"
                @click="goToEditRole(data)"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.UPDATE,
                    subject: constants.PERMISSIONS_MODEL.ROLE
                  }) && !isReadOnly(data.item)
                "
              />
            </span>
            <span :id="data.item.view_tool">
              <feather-icon
                icon="EyeIcon"
                class="text-primary action-icon icon-position-top"
                size="18"
                @click="goToViewRole(data)"
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.READ,
                    subject: constants.PERMISSIONS_MODEL.ROLE
                  }) || isReadOnly(data.item)
                "
              />
            </span>
            <span>
              <feather-icon
                v-b-tooltip.hover.v-primary
                :title="$t('tooTip.delete')"
                icon="Trash2Icon"
                class="text-danger action-icon icon-position"
                size="18"
                v-b-modal.modal-sm-remove
                v-if="
                  checkAbility({
                    action: constants.PERMISSIONS_ACTION.DELETE,
                    subject: constants.PERMISSIONS_MODEL.ROLE
                  }) && !isReadOnly(data.item)
                "
                @click="remove(data.item)"
              />
            </span>
          </div>
          <b-tooltip
            delay="100"
            variant="primary"
            :target="data.item.clone_tool"
            >{{ $t("tooTip.clone") }}</b-tooltip
          >

          <b-tooltip
            delay="100"
            variant="primary"
            :target="data.item.up_tool"
            >{{ $t("tooTip.update") }}</b-tooltip
          >
          <b-tooltip
            delay="100"
            variant="primary"
            :target="data.item.view_tool"
            >{{ $t("tooTip.view") }}</b-tooltip
          >
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRoles"
              :per-page="perPage"
              @input="handlePageChange"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <AreYouSureModal
      ref="areyousure"
      :data="role"
      :onClose="onClose"
      :removedUser="removeRole"
      :customData="customData"
    />
    <!-- <b-overlay :show="show" no-wrap> </b-overlay> -->
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  VBTooltip,
  BSkeletonTable
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import AccountService from "@/libs/api/account-service";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BOverlay,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AreYouSureModal,
    BTooltip,
    VBTooltip,
    BSkeletonTable
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      tableColumns: [
        {
          key: "role_name",
          tdClass: "",
          label: this.$t("role.tableColumns.role_name")
        },
        {
          key: "labels",
          tdClass: "role-group-width",
          thClass: "role-group-width",
          label: this.$t("role.tableColumns.labels")
        },
        {
          key: "action",
          tdClass: "role-action-width",
          label: this.$t("role.tableColumns.action")
        }
      ],
      roles: [],
      role: null,
      show: false,
      customData: {},
      totalRoles: 0,
      perPage: this.$route.query.perPage ? this.$route.query.perPage : 10,
      currentPage: this.$route.query.page
        ? parseInt(this.$route.query.page)
        : 1,
      userRole: localStorage.getItem("USER_ACCOUNT_ROLE_ID")
    };
  },
  props: ["isGroupEntity", "groupName"],
  async mounted() {
    this.$nextTick(() => {
      const { page, filter, perPage } = this.$route.query;
      if (filter) {
        this.filter = filter;
      }
      if (!page) {
        this.currentPage = 1;
        this.updateQueryParam();
      }
      if (perPage) {
        this.perPage = parseInt(perPage);
      }
    });
    await this.getAllRoles();
  },
  watch: {
    perPage() {
      if (!this.perPage) {
        this.perPage = 10;
      }
      this.updateUrl({ key: "perPage", value: this.perPage });
      this.getAllRoles();
    },
    currentPage() {
      this.updateQueryParam();
      this.getAllRoles();
    }
  },
  methods: {
    updateUrl(e) {
      this.$router.push({
        query: { ...this.$route.query, [e.key]: e.value }
      });
    },
    handlePageChange(newPage) {
      this.updateQueryParam();
    },
    updateQueryParam() {
      const query = {
        ...this.$route.query,
        page: this.currentPage.toString()
      };
      if (this.filter) {
        query.filter = this.filter;
      }
      this.$router.replace({ query }).catch(() => {});
    },
    remove(roleData) {
      if (roleData && roleData.child_role_count > 0) {
        this.customData = {
          icon: "XIcon",
          msgTxt: this.$t("roles_management.ConfirmDeleteRoleChildRoleMsgTxt"),
          isQuite: true,
          title: this.$t("roles_management.ConfirmDeleteRoleMsgTitle"),
          btnTxt: this.$t("roles_management.ConfirmDeleteRoleBtnTxt"),
          lastTxt: this.$t("roles_management.ConfirmDeleteRoleLastTxt")
        };
      } else if (roleData && roleData.user_count > 0) {
        this.customData = {
          icon: "XIcon",
          msgTxt: this.$t("roles_management.ConfirmDeleteRoleMsgTxt"),
          isQuite: true,
          title: this.$t("roles_management.ConfirmDeleteRoleMsgTitle"),
          btnTxt: this.$t("roles_management.ConfirmDeleteRoleBtnTxt"),
          lastTxt: this.$t("roles_management.ConfirmDeleteRoleLastTxt")
        };
      } else {
        this.customData = {};
      }
      this.role = { ...roleData, name: roleData.role_name };
      this.$bvModal.show("modal-sm-remove");
    },
    onClose() {
      this.$bvModal.hide("modal-sm-remove");
    },
    isReadOnly(role) {
      if (role.read_only || role.read_olny || this.userRole === role.id) {
        return true;
      } else {
        return false;
      }
    },
    async getAllRoles() {
      this.show = true;
      try {
        let response = await new AccountService().getUserRoles({
          page: parseInt(this.currentPage),
          page_size: parseInt(this.perPage)
        });
        this.show = false;

        if (response && response.data) {
          this.roles = response.data.list || [];
          this.roles = this.roles.map((u) => {
            u.del_tool = `del${u.id}`;
            u.up_tool = `up${u.id}`;
            u.view_tool = `view${u.id}`;
            u.clone_tool = `clone${u.id}`;
            return u;
          });
          this.totalRoles = response.data.pagination.total_records || 0;
        } else if (response && response.error && response.error.message) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.title,
            text: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },
    redirectAddscreen(id) {
      this.$router.push({ name: "create-role", query: { id: id } });
    },
    async removeRole(role) {
      this.$refs.areyousure.disabled = true;
      let response = await new AccountService().removeRole({
        role_id: role.id
      });

      if (response && response.data) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Role Removed",
            text: "Role removed successfully",
            icon: "EditIcon",
            variant: "success"
          }
        });
        this.onClose();
        this.getAllRoles();
        this.$refs.areyousure.disabled = false;
      } else if (response && response.error && response.error.message) {
        this.$refs.areyousure.disabled = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.error.title,
            text: response.error.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
      }
    },
    goToEditRole(role) {
      this.$router.push({ name: "edit-role", params: { id: role.item.id } });
    },
    goToViewRole(role) {
      this.$router.push({ name: "view-role", params: { id: role.item.id } });
    }
  }
};
</script>
<style lang="scss" scoped>
.table-responsive {
  height: calc(100vh - 315px);
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.user-roles-list {
  overflow-y: auto;
  height: calc(100vh - 315px) !important;
  .b-table {
    width: calc(100% - 18px) !important;
  }
}
.role-action-width {
  max-width: 160px;
  width: 160px;
  min-width: 160px;
}

.role-group-width {
  max-width: 180px;
  width: 180px;
  min-width: 180px;
  text-align: left;
  .event-Tag .media-body {
    justify-content: flex-start;
  }
}
.icon-position {
  position: relative;
  cursor: pointer;
}
.icon-position-top {
  position: relative;
  margin-right: 10px;
  cursor: pointer;
}
.add-AddUnit a {
  display: flex;
  align-items: center;
  justify-content: center;
  .add-icon-s {
    margin-right: 0px;
  }
}
</style>
