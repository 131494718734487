import APIService from "./api";

/** This class will include all services defined in rule-service */
export default class UtilityService extends APIService {
  service = "utility-service";
  async getAllGroups(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllGroups" },
      data,
      options
    );
  }
  async tagEntity(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "tagEntity" },
      data,
      options
    );
  }
  async unTagEntity(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "unTagEntity" },
      data,
      options
    );
  }
  async getGroupDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getGroupDetails" },
      data,
      options
    );
  }
  async updateGroupDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateGroupDetails" },
      data,
      options
    );
  }
  async deleteGroup(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deleteGroup" },
      data,
      options
    );
  }
  async deleteGeofence(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deleteGeofence" },
      data,
      options
    );
  }
  async createGeofence(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createGeofence" },
      data,
      options
    );
  }
  async updateGeofence(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateGeofence" },
      data,
      options
    );
  }
  async getGeofenceDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getGeofenceDetails" },
      data,
      options
    );
  }
  async getAllGeoFences(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllGeofences" },
      data,
      options
    );
  }
  async getAllRoutes(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllRoutes" },
      data,
      options
    );
  }
  async getRouteDetails(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getRouteDetails" },
      data,
      options
    );
  }
  async deleteRoute(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deleteRoute" },
      data,
      options
    );
  }
  async createRoute(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createRoute" },
      data,
      options
    );
  }
  async updateRoute(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateRoute" },
      data,
      options
    );
  }
  async getAllTemplates(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getAllTemplates" },
      data,
      options
    );
  }
  async createTemplate(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "createTemplate" },
      data,
      options
    );
  }
  async updateTemplate(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "updateTemplate" },
      data,
      options
    );
  }
  async getTemplate(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getTemplate" },
      data,
      options
    );
  }
  async getPolyMobileUnits(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getPolyMobileUnits" },
      data,
      options
    );
  }
  async getPolyMobileLabels(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getPolyMobileUnits" },
      data,
      options
    );
  }
  async getConfig(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "getConfiguration" },
      data,
      options
    );
  }
  async deleteTemplate(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "deleteTemplate" },
      data,
      options
    );
  }
  async sendConfiguration(
    data,
    options = { show_loader: true, http_headers: {}, api_version: undefined }
  ) {
    return await this.api(
      { service: this.service, method: "sendConfiguration" },
      data,
      options
    );
  }
}
